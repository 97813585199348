exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meetups-tsx": () => import("./../../../src/pages/meetups.tsx" /* webpackChunkName: "component---src-pages-meetups-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-products-cloudlab-tsx": () => import("./../../../src/pages/products/cloudlab.tsx" /* webpackChunkName: "component---src-pages-products-cloudlab-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-virtualcoach-tsx": () => import("./../../../src/pages/products/virtualcoach.tsx" /* webpackChunkName: "component---src-pages-products-virtualcoach-tsx" */),
  "component---src-pages-services-corporate-training-tsx": () => import("./../../../src/pages/services/corporate-training.tsx" /* webpackChunkName: "component---src-pages-services-corporate-training-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-student-pack-tsx": () => import("./../../../src/pages/services/student-pack.tsx" /* webpackChunkName: "component---src-pages-services-student-pack-tsx" */),
  "component---src-pages-technologies-django-training-tsx": () => import("./../../../src/pages/technologies/django-training.tsx" /* webpackChunkName: "component---src-pages-technologies-django-training-tsx" */),
  "component---src-pages-technologies-index-tsx": () => import("./../../../src/pages/technologies/index.tsx" /* webpackChunkName: "component---src-pages-technologies-index-tsx" */),
  "component---src-pages-technologies-python-tsx": () => import("./../../../src/pages/technologies/python.tsx" /* webpackChunkName: "component---src-pages-technologies-python-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-technology-tsx": () => import("./../../../src/templates/technology.tsx" /* webpackChunkName: "component---src-templates-technology-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

